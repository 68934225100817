import {
  configureStore,
  getDefaultMiddleware,
  Middleware,
} from "@reduxjs/toolkit";
import rootReducer, { RootState } from "./rootReducer";

let persistTimeout: number;
const persistKey = "state";
const localStoragePersistMiddleware: Middleware<{}, RootState> = (store) => (
  next
) => (action) => {
  if (persistTimeout) {
    clearTimeout(persistTimeout);
  }
  persistTimeout = window.setTimeout(() => {
    try {
      const serializedState = JSON.stringify(store.getState());
      localStorage.setItem(persistKey, serializedState);
    } catch (e) {
      console.error("Error: State persisting failed.", e);
    }
  }, 250);

  return next(action);
};

const getPreloadStateFromLocalStorage = () => {
  const serializedState = localStorage.getItem(persistKey);
  if (!serializedState) {
    return;
  }

  try {
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn("Restoring serialized state failed.", e, serializedState);
  }

  return {};
};

const store = configureStore({
  reducer: rootReducer,
  middleware: [localStoragePersistMiddleware, ...getDefaultMiddleware()],
  preloadedState: getPreloadStateFromLocalStorage(),
});

// export store singleton instance
export default store;
