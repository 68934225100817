import React, { FunctionComponent, useCallback } from "react";
import "./RefinementStoriesList.scss";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import { JsonSerializer } from "../../../util/JsonSerializer";
import { RefinementStory } from "../../../Model/RefinementStory";
import { RootState } from "../../../StateManagement/rootReducer";
import RefinementStoryCard from "./RefinementStoryCard/RefinementStoryCard";

export const RefinementStoriesList: FunctionComponent = () => {
  const stories = useSelector<RootState, RefinementStory[]>((state) =>
    state.refinementPreparation.stories.map((storyJson) =>
      JsonSerializer.fromJson<RefinementStory>(RefinementStory, storyJson)
    )
  );

  const renderStories = useCallback(() => {
    return stories.map((story) => (
      <RefinementStoryCard story={story} key={story.id} />
    ));
  }, [stories]);

  return (
    <Accordion className={"RefinementStoriesList"}>{renderStories()}</Accordion>
  );
};

export default RefinementStoriesList;
