import React, { FunctionComponent, useCallback } from "react";
import "./RefinementPreparation.scss";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addRefinementStory } from "../../StateManagement/features/refinement-preparation";
import { RefinementStory } from "../../Model/RefinementStory";
import RefinementStoriesList from "./RefinementStoriesList/RefinementStoriesList";
import { RefinementStoryExcel } from "../../Model/RefinementStoryExcel";
import { RootState } from "../../StateManagement/rootReducer";
import { JsonSerializer } from "../../util/JsonSerializer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const RefinementPreparation: FunctionComponent = () => {
  const dispatch = useDispatch();

  const stories = useSelector<RootState, RefinementStory[]>((state) =>
    state.refinementPreparation.stories.map((storyJson) =>
      JsonSerializer.fromJson<RefinementStory>(RefinementStory, storyJson)
    )
  );

  const addStory = useCallback(() => {
    const story = new RefinementStory();
    dispatch(addRefinementStory(story.toJson()));
  }, [dispatch]);

  const downloadExcel = useCallback(() => {
    const excel = new RefinementStoryExcel(stories);
    const refinementDate =
      `${new Date().getFullYear()}-` +
      `${new Date().getMonth() + 1}`.padStart(2, "0");
    const refinementStories = stories
      .map((story) => story.jiraReference)
      .join(" ");
    const filename = `Refinement ${refinementDate} ${refinementStories}.xlsx`;
    excel.download(filename);
  }, [stories]);

  return (
    <div className={"RefinementPreparation"}>
      <Row>
        <Col>
          <RefinementStoriesList />
        </Col>
      </Row>
      <Row className={"buttons-row"}>
        <Col>
          <Button onClick={addStory}>
            <FontAwesomeIcon icon="plus" className={"mr-2"} />
            Add Story
          </Button>
        </Col>
        <Col className={"text-right"}>
          <Button onClick={downloadExcel} variant="success">
            <FontAwesomeIcon icon="download" className={"mr-2"} />
            Download Excel
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default RefinementPreparation;
