import React, { Fragment, FunctionComponent, useCallback } from "react";
import "./RefinementStoryHoursBreakdown.scss";
import { Col, Form, Row } from "react-bootstrap";
import { RefinementStory } from "../../../../../Model/RefinementStory";
import { useDispatch } from "react-redux";
import { updateBreakdownHours } from "../../../../../StateManagement/features/refinement-preparation";
import {
  HoursEntryWithChildren,
  HoursEntryWithoutChildren,
} from "../../../../../Type/HoursBreakdown";

export interface Props {
  story: RefinementStory;
}

export const RefinementStoryHoursBreakdown: FunctionComponent<Props> = (
  props
) => {
  const { story } = props;
  const dispatch = useDispatch();

  const renderHoursBreakdownEntryWithoutChildren = useCallback(
    (entry: HoursEntryWithoutChildren, path: number[]) => {
      const key = `form-story-${story.id}-hoursbreakdown-${path.join("-")}`;
      if (0 === entry.name.trim().length) {
        return <h4 key={key}>&nbsp;</h4>;
      }

      const onChange = (e: React.ChangeEvent) => {
        const target = e.target;
        if (target instanceof HTMLInputElement) {
          const entryHours = parseFloat(target.value);
          dispatch(
            updateBreakdownHours({
              storyId: story.id,
              hours: entryHours,
              path: path,
            })
          );
        }
      };

      return (
        <Form.Group
          key={key}
          as={Row}
          controlId={`form-story-${story.id}-hoursbreakdown-${path.join("-")}`}
        >
          <Form.Label column xs={8} sm={4} md={3}>
            {entry.name}
          </Form.Label>
          <Col xs={4} sm={8} md={9}>
            <Form.Control
              type="number"
              value={entry.hours ?? 0}
              onChange={onChange}
            />
          </Col>
        </Form.Group>
      );
    },
    [dispatch, story]
  );

  const renderHoursBreakdownEntryWithChildren = useCallback(
    (entry: HoursEntryWithChildren, path: number[]) => {
      return (
        <Fragment
          key={`form-story-${story.id}-hoursbreakdown-${path.join("-")}`}
        >
          <h4>{entry.name}</h4>
          {entry.children.map((child, index) =>
            renderHoursBreakdownEntryWithoutChildren(child, [...path, index])
          )}
        </Fragment>
      );
    },
    [story.id, renderHoursBreakdownEntryWithoutChildren]
  );

  const renderHoursBreakdownEntry = useCallback(
    (
      entry: HoursEntryWithChildren | HoursEntryWithoutChildren,
      index: number
    ) => {
      if ("children" in entry) {
        return renderHoursBreakdownEntryWithChildren(entry, [index]);
      }

      return renderHoursBreakdownEntryWithoutChildren(entry, [index]);
    },
    [
      renderHoursBreakdownEntryWithChildren,
      renderHoursBreakdownEntryWithoutChildren,
    ]
  );

  return (
    <div className={"RefinementStoryHoursBreakdown"}>
      <h3>Hours Breakdown</h3>
      {story.hoursBreakdown.map(renderHoursBreakdownEntry)}
      <hr />
      <Form.Group
        as={Row}
        controlId={`form-story-${story.id}-hoursbreakdown-pt`}
      >
        <Form.Label column xs={8} sm={4} md={3}>
          Person Days
        </Form.Label>
        <Col xs={4} sm={8} md={9}>
          <Form.Control
            type="number"
            value={story.getPersonDays()}
            disabled={true}
          />
        </Col>
      </Form.Group>
      <Form.Group
        as={Row}
        controlId={`form-story-${story.id}-hoursbreakdown-sp`}
      >
        <Form.Label column xs={8} sm={4} md={3}>
          Story Points ({story.spFactor}x)
        </Form.Label>
        <Col xs={4} sm={8} md={9}>
          <Form.Control
            type="number"
            value={story.getStoryPoints()}
            disabled={true}
          />
        </Col>
      </Form.Group>
    </div>
  );
};

export default RefinementStoryHoursBreakdown;
