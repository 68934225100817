import React, { FunctionComponent } from "react";
import "./App.scss";
import { Container, Tab, Tabs } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import RefinementPreparation from "../RefinementPreparation/RefinementPreparation";

library.add(faPlus, faDownload, faTrash);

export const App: FunctionComponent = () => {
  return (
    <Container className="App">
      <h1>SCRUMish Toolkit</h1>
      <Tabs defaultActiveKey="refinementPrep" id="main-tabs">
        <Tab eventKey="refinementPrep" title="Refinement Preparation">
          <RefinementPreparation />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default App;
