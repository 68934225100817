import {
  JsonFromSerializable,
  JsonSerializable,
  SerializationMapping,
} from "./JsonSerializable";

export class JsonSerializer {
  /**
   * Serializes a JsonSerializable class instance to JSON.
   *
   * @param object
   */
  public static toJson<T extends JsonSerializable<T>>(
    object: JsonSerializable<T>
  ): JsonFromSerializable<T> {
    const data: Record<string, unknown> = {};
    const keys: [keyof typeof object] = Object.keys(
      object.serializationMapping
    ) as [keyof typeof object];

    for (const key of keys) {
      const value: unknown = object[key];
      if (null === value) {
        data[key] = null;
      } else if (value instanceof JsonSerializable) {
        data[key] = JsonSerializer.toJson(value);
      } else if (value instanceof Date) {
        data[key] = value.getTime();
      } else if (
        Array.isArray(value) &&
        value.every((x) => x instanceof JsonSerializable)
      ) {
        data[key] = value.map((n) => JsonSerializer.toJson(n));
      } else {
        data[key] = value;
      }
    }

    return data as JsonFromSerializable<T>;
  }

  /**
   * Deserializes a JSON into a new JsonSerializable class instance.
   *
   * @param type
   * @param json
   */
  public static fromJson<T extends JsonSerializable<T>>(
    type: new () => T,
    json: JsonFromSerializable<T>
  ): T {
    const object: T = new type();
    const serializationMapping = object.serializationMapping;
    const keys: [keyof T] = Object.keys(serializationMapping) as [keyof T];

    for (const key of keys) {
      const keyMapping = serializationMapping[key];
      const value = json[key] as any;

      // if no validation is wanted, just set the value and move on
      if (true === keyMapping) {
        object[key] = value;
        continue;
      }

      // if validation fails, output error and skip the property
      JsonSerializer.validateType<T>(keyMapping, value);

      if ("string" === typeof keyMapping) {
        object[key] = value;
      } else if (Date === keyMapping) {
        object[key] = new Date(value) as any;
      } else {
        const nestedType = keyMapping as new () => JsonSerializable<any>;
        if (Array.isArray(value)) {
          object[key] = value.map((o: JsonFromSerializable<any>) =>
            JsonSerializer.fromJson(nestedType, o)
          ) as any;
        } else {
          object[key] = JsonSerializer.fromJson(nestedType, value) as any;
        }
      }
    }

    return object;
  }

  /**
   * Validates a value against a type specified in the serialization mapping.
   *
   * @param keyMapping
   * @param value
   * @protected
   */
  protected static validateType<T extends JsonSerializable<T>>(
    keyMapping: SerializationMapping<T>[keyof T],
    value: unknown
  ): boolean {
    let error: string | undefined;

    if ("string" === typeof keyMapping && keyMapping !== typeof value) {
      error =
        'Type validation failed. Expected "' +
        keyMapping +
        '", got "' +
        typeof value +
        '".';
    } else if (Date === keyMapping && isNaN(new Date(value as any) as any)) {
      error = 'Type validation failed. Expected Date, got "' + value + '".';
    }

    if (error) {
      throw new Error(error);
    }

    return !error;
  }
}
