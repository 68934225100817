import XLSX from "xlsx";
import { RefinementStory } from "./RefinementStory";

export class RefinementStoryExcel {
  constructor(protected stories: RefinementStory[] = []) {}

  protected createWorkbook() {
    const workBook = XLSX.utils.book_new();

    this.stories.forEach((story) => {
      const workSheet: XLSX.WorkSheet = {};
      this.addHoursBreakdown(workSheet, story);
      this.addAdditionalInformation(workSheet, story);

      // set range
      const range: XLSX.Range = { s: { r: 0, c: 0 }, e: { r: 40, c: 20 } };
      workSheet["!ref"] = XLSX.utils.encode_range(range);

      // set column widths
      workSheet["!cols"] = [
        { wch: 8 },
        { wch: 16 },
        { wch: 8 },
        { wch: 8 },
        { wch: 8 },
        { wch: 16 },
      ];

      XLSX.utils.book_append_sheet(workBook, workSheet, story.jiraReference);
    });

    return workBook;
  }

  protected setCell(
    workSheet: XLSX.WorkSheet,
    col: number,
    row: number,
    cell: XLSX.CellObject
  ) {
    const cellRef = XLSX.utils.encode_cell({ r: row, c: col });
    workSheet[cellRef] = cell;
  }

  protected addTextCell(
    workSheet: XLSX.WorkSheet,
    text: string,
    col: number,
    row: number
  ) {
    const cell: XLSX.CellObject = {
      t: "s",
      v: text,
    };
    this.setCell(workSheet, col, row, cell);
  }

  protected addNumberCell(
    workSheet: XLSX.WorkSheet,
    number: number | undefined,
    col: number,
    row: number
  ) {
    const cell: XLSX.CellObject = {
      t: "n",
      v: number,
    };
    this.setCell(workSheet, col, row, cell);
  }

  protected addHoursBreakdown(
    workSheet: XLSX.WorkSheet,
    story: RefinementStory
  ) {
    let row = 0;

    story.hoursBreakdown.forEach((entry) => {
      this.addTextCell(workSheet, entry.name, 0, row);
      row++;
      if ("children" in entry) {
        entry.children.forEach((child) => {
          this.addTextCell(workSheet, child.name, 1, row);
          this.addNumberCell(workSheet, child.hours, 2, row);
          row++;
        });
      } else {
        this.addNumberCell(workSheet, entry.hours, 2, row - 1);
      }
    });
    row++;
    row++;
    const ptCol = 2;
    const spCol = 3;
    this.addTextCell(workSheet, "PT", ptCol, row);
    this.addTextCell(workSheet, "SP", spCol, row);
    row++;
    const sumStart = XLSX.utils.encode_cell({ c: ptCol, r: 0 });
    const sumEnd = XLSX.utils.encode_cell({ c: ptCol, r: row - 2 });
    const sumCell = XLSX.utils.encode_cell({ c: ptCol, r: row });
    this.setCell(workSheet, ptCol, row, {
      t: "n",
      f: `SUM(${sumStart}:${sumEnd})/8`,
    });
    this.setCell(workSheet, spCol, row, {
      t: "n",
      f: `${sumCell}*${story.spFactor}`,
    });
  }

  public addAdditionalInformation(
    workSheet: XLSX.WorkSheet,
    story: RefinementStory
  ) {
    const col = 5;
    let row = 0;

    story.additionalInformation.forEach((info) => {
      this.addTextCell(workSheet, info.name, col, row);
      row++;
      info.text.split("\n").forEach((infoLine) => {
        this.addTextCell(workSheet, infoLine, col, row);
        row++;
      });
      row++;
    });
  }

  public download(filename: string = "download.xlsx") {
    const workBook = this.createWorkbook();
    XLSX.writeFile(workBook, filename);
  }
}
