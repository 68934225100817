import React, { FunctionComponent, useCallback } from "react";
import "./RefinementStoryCard.scss";
import { Accordion, Button, Card, Form } from "react-bootstrap";
import { RefinementStory } from "../../../../Model/RefinementStory";
import { useDispatch } from "react-redux";
import {
  removeRefinementStory,
  updateRefinementStory,
} from "../../../../StateManagement/features/refinement-preparation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RefinementStoryHoursBreakdown from "./RefinementStoryHoursBreakdown/RefinementStoryHoursBreakdown";
import RefinementStoryAdditionalInformation from "./RefinementStoryAdditionalInformation/RefinementStoryAdditionalInformation";

export interface Props {
  story: RefinementStory;
}

export const RefinementStoryCard: FunctionComponent<Props> = (props) => {
  const { story } = props;
  const dispatch = useDispatch();

  const changeJiraReference = useCallback(
    (e: React.ChangeEvent) => {
      const target = e.target;
      if (target instanceof HTMLInputElement) {
        story.jiraReference = target.value;
        dispatch(updateRefinementStory(story.toJson()));
      }
    },
    [dispatch, story]
  );

  const deleteJiraReference = useCallback(
    (story: RefinementStory) => {
      dispatch(removeRefinementStory(story.toJson()));
    },
    [dispatch]
  );

  return (
    <Card className={"RefinementStoryCard"}>
      <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey={story.id}>
          {story.jiraReference}
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={story.id}>
        <Card.Body>
          <Form>
            <h3>General</h3>
            <Form.Group controlId={`form-story-${story.id}-jirareference`}>
              <Form.Label>JIRA Reference</Form.Label>
              <Form.Control
                type="text"
                maxLength={31}
                value={story.jiraReference}
                onChange={(e) => changeJiraReference(e)}
              />
            </Form.Group>
            <RefinementStoryHoursBreakdown story={story} />
            <RefinementStoryAdditionalInformation story={story} />
          </Form>
          <Button variant="danger" onClick={() => deleteJiraReference(story)}>
            <FontAwesomeIcon icon="trash" className={"mr-2"} />
            Delete Story
          </Button>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default RefinementStoryCard;
