import React, { FunctionComponent, useCallback } from "react";
import "./RefinementStoryAdditionalInformation.scss";
import { RefinementStory } from "../../../../../Model/RefinementStory";
import { AdditionalInformation } from "../../../../../Type/AdditionalInformation";
import { Col, Form, Row } from "react-bootstrap";
import { updateAdditionalInformation } from "../../../../../StateManagement/features/refinement-preparation";
import { useDispatch } from "react-redux";

export interface Props {
  story: RefinementStory;
}

export const RefinementStoryAdditionalInformation: FunctionComponent<Props> = (
  props
) => {
  const { story } = props;
  const dispatch = useDispatch();

  const renderAdditionalInformation = useCallback(
    (information: AdditionalInformation, index: number) => {
      const onChange = (e: React.ChangeEvent) => {
        const target = e.target;
        if (
          target instanceof HTMLInputElement ||
          target instanceof HTMLTextAreaElement
        ) {
          dispatch(
            updateAdditionalInformation({
              storyId: story.id,
              informationName: information.name,
              informationText: target.value,
            })
          );
        }
      };

      return (
        <Form.Group
          key={`form-story-${story.id}-additionalinformation-${index}`}
          as={Row}
          controlId={`form-story-${story.id}-additionalinformation-${index}`}
        >
          <Form.Label column xs={8} sm={4} md={3}>
            {information.name}
          </Form.Label>
          <Col xs={4} sm={8} md={9}>
            {"textarea" === information.type && (
              <Form.Control
                as="textarea"
                rows={information.textAreaRows}
                value={information.text}
                onChange={onChange}
              />
            )}
            {"input" === information.type && (
              <Form.Control
                type="text"
                value={information.text}
                onChange={onChange}
              />
            )}
          </Col>
        </Form.Group>
      );
    },
    [dispatch, story.id]
  );

  return (
    <div className={"RefinementStoryAdditionalInformation"}>
      <h3>Additional Information</h3>
      {story.additionalInformation.map(renderAdditionalInformation)}
    </div>
  );
};

export default RefinementStoryAdditionalInformation;
