import { JsonSerializer } from "../util/JsonSerializer";

export type SerializationMapping<T> = {
  [P in keyof T]?:
    | (new () => JsonSerializable<any>)
    | (new () => Date)
    | true
    | string;
};

// this partial is a bit broad and includes too much, but there doesn't seem to be a more precise solution for now
export type JsonFromSerializable<T extends JsonSerializable<T>> = Partial<
  Record<keyof T, unknown>
>;

export abstract class JsonSerializable<T extends JsonSerializable<T>> {
  public serializationMapping: SerializationMapping<T> = {};

  /**
   * Serializes the JsonSerializable into a JSON object.
   */
  public toJson(): JsonFromSerializable<T> {
    return JsonSerializer.toJson<T>(this);
  }
}
