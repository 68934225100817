import { combineReducers } from "redux";
import refinementPreparationReducer from "./features/refinement-preparation";

const rootReducer = combineReducers({
  refinementPreparation: refinementPreparationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
