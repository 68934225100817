import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JsonFromSerializable } from "../../util/JsonSerializable";
import { RefinementStory } from "../../Model/RefinementStory";
import { HoursBreakdown } from "../../Type/HoursBreakdown";
import { AdditionalInformation } from "../../Type/AdditionalInformation";

interface RefinementPreparationState {
  stories: JsonFromSerializable<RefinementStory>[];
}

const initialState: RefinementPreparationState = {
  stories: [],
};

const refinementPreparationSlice = createSlice({
  name: "refinement-preparation",
  initialState: initialState,
  reducers: {
    addRefinementStory: (
      state,
      action: PayloadAction<JsonFromSerializable<RefinementStory>>
    ) => {
      const storyAlreadyAdded = !!state.stories.find(
        (r) => r.id === action.payload.id
      );
      if (!storyAlreadyAdded) {
        state.stories.push(action.payload);
      }
      return state;
    },
    updateRefinementStory: (
      state,
      action: PayloadAction<JsonFromSerializable<RefinementStory>>
    ) => {
      const storyIndex = state.stories.findIndex(
        (r) => r.id === action.payload.id
      );
      if (0 <= storyIndex) {
        state.stories[storyIndex] = action.payload;
      }
      return state;
    },
    removeRefinementStory: (
      state,
      action: PayloadAction<JsonFromSerializable<RefinementStory>>
    ) => {
      state.stories = state.stories.filter((r) => r.id !== action.payload.id);
      return state;
    },
    updateBreakdownHours: (
      state,
      action: PayloadAction<{ storyId: string; hours: number; path: number[] }>
    ) => {
      const path = action.payload.path;
      const entryHours = action.payload.hours;
      const storyIndex = state.stories.findIndex(
        (r) => r.id === action.payload.storyId
      );
      if (0 > storyIndex || isNaN(entryHours)) {
        return state;
      }
      const hoursBreakdown = state.stories[storyIndex]
        .hoursBreakdown as HoursBreakdown;

      const mainHoursBreakdownEntry = hoursBreakdown[path[0]];
      if (1 === path.length && "hours" in mainHoursBreakdownEntry) {
        mainHoursBreakdownEntry.hours = entryHours;
      } else if (2 === path.length && "children" in mainHoursBreakdownEntry) {
        const childHoursBreakdownEntry =
          mainHoursBreakdownEntry.children[path[1]];
        childHoursBreakdownEntry.hours = entryHours;
      }
      return state;
    },
    updateAdditionalInformation: (
      state,
      action: PayloadAction<{
        storyId: string;
        informationName: string;
        informationText: string;
      }>
    ) => {
      const storyIndex = state.stories.findIndex(
        (r) => r.id === action.payload.storyId
      );
      if (0 > storyIndex) {
        return state;
      }
      const additionalInformation = state.stories[storyIndex]
        .additionalInformation as AdditionalInformation[];
      additionalInformation
        .filter((info) => action.payload.informationName === info.name)
        .forEach((info) => (info.text = action.payload.informationText));

      return state;
    },
  },
});

export const {
  addRefinementStory,
  updateRefinementStory,
  removeRefinementStory,
  updateBreakdownHours,
  updateAdditionalInformation,
} = refinementPreparationSlice.actions;

export default refinementPreparationSlice.reducer;
