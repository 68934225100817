import { JsonSerializable } from "../util/JsonSerializable";
import { nanoid } from "nanoid";
import { HoursBreakdown } from "../Type/HoursBreakdown";
import { AdditionalInformation } from "../Type/AdditionalInformation";

export class RefinementStory extends JsonSerializable<RefinementStory> {
  public id: string = nanoid();
  public jiraReference: string = "1F-…";
  public readonly spFactor = 1.7;

  public hoursBreakdown: HoursBreakdown = [
    {
      name: "Analysis",
      children: [
        { name: "Story Creation", hours: 4 },
        { name: "Structure", hours: 4 },
        { name: "Test plan", hours: 1 },
        { name: "Architecture", hours: 4 },
      ],
    },
    { name: "" },
    {
      name: "Development",
      children: [{ name: "Change Steps" }, { name: "Unit Tests" }],
    },
    { name: "" },
    {
      name: "Testing",
      children: [{ name: "Execute Test Plan", hours: 3 }],
    },
    { name: "" },
    {
      name: "Deployment to INT",
      hours: 1,
    },
    {
      name: "Documentation",
      hours: 1,
    },
  ];

  public additionalInformation: AdditionalInformation[] = [
    { name: "Check for DoR", type: "input", text: "yes" },
    {
      name: "Affected code",
      type: "textarea",
      textAreaRows: 3,
      text: "Java classes: -\nUI changes: -\nDB Changes: -",
    },
    { name: "What is changing here?", type: "textarea", text: "…" },
    { name: "Affected Dependencies", type: "textarea", text: "-" },
    {
      name: "Existing documentation? (manual, online help, technical…)",
      type: "textarea",
      text: "…",
    },
    { name: "Affected user groups", type: "textarea", text: "-" },
  ];

  constructor() {
    super();

    this.serializationMapping = {
      id: "string",
      jiraReference: "string",
      hoursBreakdown: true,
      additionalInformation: true,
    };
  }

  public getPersonDays(): number {
    let hours = 0;
    this.hoursBreakdown.forEach((entry) => {
      if ("hours" in entry && entry.hours) {
        hours += entry.hours;
      }
      if ("children" in entry) {
        entry.children.forEach((child) => {
          hours += child.hours ?? 0;
        });
      }
    });

    return hours / 8.0;
  }

  public getStoryPoints(): number {
    return this.spFactor * this.getPersonDays();
  }
}
